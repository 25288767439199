.carousel-box {
  cursor:grab ;
}

.card-testimonial {
  width: 100%;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column;
  z-index: 9999;
  padding-top: 2rem;
  color: rgb(253, 236, 255);
}

.card-name {
  font-family: 'Oceanwide', sans-serif;
  font-weight: bold;
  text-align: right;
  font-size: 1.2rem;
  margin-top: 0.7rem;
  width: 70%;
}


.card-description {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'ZT Gatha', sans-serif;
  height: auto;
  width: 70%;
  text-align: justify;
  font-style: italic;
  padding: 2rem 2rem 1rem 2rem;
  border-radius: 7px;
  background-color: rgba(104, 32, 86, 0.568);
}
.rec.rec-arrow {
  color:white;
}
.rec-dot_active {
  background-color: rgba(255, 126, 223, 0.568) !important;
  box-shadow: 0 0 1px 3px rgba(104, 32, 86, 0.568) !important;
}
.rec-dot:focus,
.rec-dot:hover {
  box-shadow: 0 0 1px 3px rgba(104, 32, 86, 0.568) !important;
}
.rec-dot_active:focus,
.rec-dot_active:hover {
  background-color: rgba(104, 32, 86, 0.568) !important;
  box-shadow: 0 0 1px 3px rgba(51, 10, 40, 0.568) !important;
}
.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 1px rgb(93, 15, 117);
}
.rec.rec-arrow:hover {
  background-color:rgb(93, 37, 107) !important;
}
.rec.rec-arrow:focus,
.rec.rec-arrow:active,

.rec.rec-arrow:active {
  background-color: red;
}
.quote {
  width: 60px !important;
  margin-bottom: 9rem;
  margin-left: -2.1rem;
  padding-right: 0.5rem;
  position: absolute;
  top: -100px;
}
.quote-img {
  position: relative;
  display: flex;
  
  justify-content: flex-start;
  align-items: flex-start;

}




@media (max-width: 720px) {
  .card-description {
    width: 100%
  }
  .phone-hide {
    display: none !important;
  }
  .card-testimonial {
    width: 90%;
  }
  .quote {
    position:relative;
    margin-left: -3rem;
    padding-right: 0.5rem;
    margin: 0;
    margin-left: -3rem;
    margin-top: -4rem;
  }
  .quote-img {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
