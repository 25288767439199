.talleres-container {
    width: 100%;
    min-height: 100vh;
    max-width: 100%;
    overflow-x: hidden;
    height: auto;
    display: flex;
    flex-direction: row;
    z-index: 2;
    background: rgb(134,80,121);
    background: linear-gradient(25deg, rgba(134,80,121,1) 0%, rgba(127,90,161,1) 100%);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.talleres-list {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
    flex-grow: 1;
    margin-top: 2rem;
}
.embla {
    display: flex;
    flex-direction: row;
}
.taller-card {
    display: flex;
    width: 100%;
    min-height: 650px;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: white;
    justify-content: space-between !important;
    margin-bottom: 1.5rem;
    border-radius: 15px;
    font-family: 'Lexend', sans-serif;
    box-shadow: 2px 2px 14px -2px rgba(0,0,0,0.48);
    -webkit-box-shadow: 2px 2px 14px -2px rgba(0,0,0,0.48);
    -moz-box-shadow: 2px 2px 14px -2px rgba(0,0,0,0.48);
    transition: scale 0.6s;
    margin-right: 1rem;
}
.taller-card:hover {
    cursor: pointer;
}
.center {
    text-align: center;
}
.taller-title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
    border-left: 6px solid rgb(228, 120, 255);
    padding-left: 1rem;
    margin-bottom: 1rem;
}
.taller-desc {
    font-size: 0.9rem;
    line-height: 1.1rem;
    text-align: justify;
}
.taller-profes {
    font-size: 0.8rem;
    line-height: 1rem;
    text-align: justify;
    border-left: 4px solid rgb(228, 120, 255);
    padding-left: 1rem;
    text-align: left;
}
.taller-profes-ul {
    display: flex;
    justify-content: center;
    align-items: flex-end !important;
    margin-top: 1.4rem;
}

.taller-info {
    padding: 1.5rem;
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}
.taller-img {
    width: 100%;
}
.img-taller {
    width: 100%; /* Ancho de la imagen al 100% del contenedor */
    height: 100%; /* Altura de la imagen al 100% del contenedor */
    object-fit: cover; /* La imagen cubrirá todo el espacio del contenedor */
    border-radius: 15px;
}
.btn-info-div {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    align-self: flex-end;
    justify-self: flex-end;
}
.btn-info {
    padding: 0.7rem 1.5rem 0.8rem 1.5rem;
    background-color: rgb(194, 87, 194);
    color: white;
    border-radius: 25px;
    transition: all 0.3s;
}
.btn-info:hover {
    background-color: rgb(211, 113, 211);
}
@media (max-width: 720px) {
    .taller-card {
        width: 100% !important;
        min-height: 625px !important;
    }
    .talleres-list {
        width: 100%;
        padding-left: 1rem;
    }
    .img-taller {
        width: 100%;
        border-radius: 15px;
    }
    .inverted-color {
        background-color: rgb(169, 117, 182);
    }
}
@media (max-width: 1366px) {
    .taller-card {
        width: 100%;
        max-width: 100%;
        min-height: 710px;
    }
}
@media (min-width: 1367px) and (max-width: 1921px)  {
    
    .taller-card {
        width: 100%;
        max-width: 100%;
        
    }
}
@media (min-width: 1920px) {
    .taller-card {
        width: 100%;
        max-width: 100%;
    }
    .talleres-list {
        width: 65%;
    }
}