.ourteam-container {
    width: 100%;
    height: auto;
    min-height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fffafe;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;
    z-index: 50;
    position: relative;
    padding: 1rem 0 2rem 0;

}

.integrante-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: transparent;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.integrante-nombre {
    font-size: 1.2rem;
    font-weight: bolder;
    line-height: 13px;
    margin-bottom: 0.5rem;
}

.integrante-formacion {
    font-size: 0.9rem;
    font-family: "Open Sans", sans-serif;
    line-height: 1rem;
}

.integrante-desc {
    margin-top: 0.1rem;
    font-size: 0.8rem;
    text-align: justify;
}

.avatar-img {
    border-radius: 50%;
    width: 180px;
    border: 4px solid rgb(227, 227, 227);
    margin-bottom: 1rem;
    box-shadow: 0 5px 15px 1px #5959597a;
    transition: all 0.7s;
}

.avatar-img:hover {
    cursor: pointer;
}

.personal-info:hover {
    cursor: pointer;
}
.equipo-cards:hover {
    cursor:grab !important;
}
.avatar {}

.dialog-team {
    background-color: rgba(43, 43, 43, 0.692);
}

.dialog-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center !important;
    padding: 2rem;
    font-family: 'Lexend', sans-serif;
    border-radius: 15px;
    background-color: rgba(15, 15, 15, 0.755);
}

.nombre-dialog {
    font-size: 1.5rem;
    line-height: 25px;
    color: white;
}

.formacion-dialog {
    text-align: center;
    color: white;
}

hr.rounded {
    border-top: 8px solid #ffffff;
    border-radius: 5px;
    width: 20%;
}

hr.roundedshort {
    border-top: 8px solid #ffffff;
    border-radius: 5px;
    width: 10%;
}

.descripcion-dialog {
    font-size: 1.2rem;
    color: white;
}

.dialog-img {
    border-radius: 50%;
    width: 280px;
    margin-bottom: 1rem;
}

.personal-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    transition: all 0.7s;
}

.titulo-equipo {
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.equipo-cards {
    display: flex;
    flex-direction: row;
}

.ourteam-container h2 {
    color: #a879ff;

    text-align: center;
    font-family: 'Oceanwide', sans-serif;

}

.ourteam-container h1 {
    font-size: 3.6rem;
    padding-bottom: 1rem;
    font-family: 'Oceanwide', sans-serif;
    color: #ffeeea;
    z-index: 50;
    text-align: left;
    margin-bottom: -1rem;
}

.ourteam-flex-container {

    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-family: 'Lexend';
    padding-top: 2rem;
    padding-bottom: 3rem;
}
.integrante-desc-in {
    font-size: 1rem;
    font-style: italic;
    font-family: "Open Sans", sans-serif;
    margin-top: 1.5rem;
    width: 70%;
    color: rgb(68, 68, 68);
    justify-self: center;
    align-self: center;
}
img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
@media (max-width: 720px) {
    .ourteam-container {
        flex-direction: column;
    }

    .ourteam-flex-container {
        flex-direction: column;
        width: 90%;
    }

    .equipo-cards {
        flex-direction: column;
    }

    .integrante-card {
        width: 100%;
        flex-direction: column;
        margin-bottom: 1rem;
        border-left: 0;
        border-radius: 15px;
    }
    .integrante-desc-in {
        width: 85%;
    }
    .personal-info {
        width: 100%;
        text-align: center;
    }

    .dialog-img {
        padding: 2rem !important;
    }

    .avatar {
        margin: 0;
    }

    .integrante-desc {
        padding: 0.7rem;
        text-align: center;
    }

    .titulo-equipo {
        margin: 0;
        margin-bottom: 1rem;
    }
}

@media (max-width: 1500px) {}