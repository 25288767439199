.cursodetalle-container {
    width: 100%;
    height: auto;
    min-height: 100vh;
    display: flex;
    background-image: url('../../assets/img/cursos-bg.jpg');
    background-size: cover;
    overflow-x: hidden;
    padding: 3rem;
    margin: 0;
    font-family: 'Lexend', sans-serif;
    flex-direction: column;
}
.card-curso-detalle {
    width: 80%;
    display: flex;
    flex-direction: column;
    height: auto;
    background-color: rgb(255, 255, 255);
    border-radius: 30px;
    justify-self: center;
    align-self: center;
    margin-top: 2rem;
    padding: 2.5rem 3rem 2rem 3rem;
}
.btn-volver {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.btn-volver-txt {
    color: white !important;
    font-size: 1.5rem;
    transition: all 0.7s;
}
.btn-volver-txt:hover {
    scale: 1.1;
}

.logo-cursos {
    width: 120px;
    height: 80px;
    filter: brightness(1000);
}

.titulo-curso-detalle {
    color: black;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.7rem;
    display: flex;
    padding-left: 2rem;
    margin-left: 2rem;
    border-left: 6px solid violet;
    margin-bottom: 1.3rem;
}

.descripcion-curso-detalle {
    text-align: justify;
    padding: 0 2rem 0 2rem;
}

.curso-disertantes {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.curso-disertantes .integrante-card {
    width: 100%;
    zoom: 0.8;
}

.curso-disertantes .integrante-card:last-of-type {
    margin-left: 1rem;
}

.informacion-curso-detalle {
    display: flex;
    flex-direction: row;
}

.profesores-div-detalle {
    margin-top: 0.7rem;
    display: flex;
    flex-direction: row;
}

.video-curso-detalle {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    margin-top: 2rem;
}

.video-curso {
    margin-top: 1rem;
    margin-bottom: 2rem;
    border-radius: 10px;
    width: 82%;
    height: 300 px;
    -webkit-box-shadow: 0px 21px 7px -16px rgba(0, 0, 0, 0.37);
    -moz-box-shadow: 0px 21px 7px -16px rgba(0, 0, 0, 0.37);
    box-shadow: 0px 21px 7px -16px rgba(0, 0, 0, 0.37);
}
.video-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.descripcion-video {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0rem 2rem 0 2rem;
    text-align: justify;
}
.desc-video-txt {
    margin-top: 1rem;
    margin-bottom: -1.5rem;
}

.certificaciones {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.certificaciones-icon-div {
    display: flex;
    width: 150px;
    height: 150px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:#A00ACF;
    background: #A00ACF;
    background: linear-gradient(to right, #A00ACF 0%, #756FCF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
}
.certificaciones-icon {

}
.certificaciones-text {

}
.subtitulo-curso-detalle {
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0.5rem 1rem 0.5rem 1rem;
    color: white;
    background: rgb(155,2,189);
    background: linear-gradient(75deg, rgba(155,2,189,1) 0%, rgba(224,69,192,1) 100%);
    border-top-left-radius: 23px;
    border-bottom-right-radius: 23px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.btn-inscribirse {
    width: 80%;
    padding: 1rem;
    background: rgb(177,90,196);
    background: linear-gradient(75deg, rgba(177,90,196,1) 0%, rgba(224,69,104,1) 100%);
    color: white;
    border-radius: 10px;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: bold;
    transition: all 0.3s;
}
.btn-inscribirse:hover {
    background: rgb(202, 110, 223);
    background: linear-gradient(75deg, rgb(215, 120, 236) 0%, rgb(252, 97, 133) 100%);
}
.btn-inscribirse-div {
    width: 50%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}
.frase-curso {
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
    text-align: center;
    border-radius: 10px;
    background-color: rgba(243, 243, 243, 0.425);
    margin-bottom: 1rem;
    font-style: italic;
}
.autor-frase-curso {
    font-style:normal;
    font-weight: bold;
    text-align: right;
    margin-top: 1rem;
}

.accordion-button:not(.collapsed) {
    color: #ffffff;
    background: rgb(155,2,189);
background: linear-gradient(75deg, rgba(155,2,189,1) 0%, rgba(224,69,192,1) 100%);
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    font-weight: bold;
}
.accordion-button:focus {
    box-shadow: none;
    border-color: rgba(0,0,0,.125);
}
.info-inscribirse {
    display: flex;
    flex-direction: row;
}
.acordion-info-div {
    width: 50%;
    display: flex;
    justify-self: center;
    align-self: center;
    justify-content: center;
    align-content: center;
}
.acordion-info {
    width: 100%;
}


@media (max-width: 720px) {
    .cursodetalle-container h1 {
        font-size: 3rem;
        font-family: 'Oceanwide', sans-serif;
    }
    .card-curso-detalle {
        width: 120% !important;
        flex-direction: column;
        padding: 0;
    }
    .titulo-curso-detalle{
        margin-top: 2.5rem;
    }
    .cursodetalle-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .video-curso-detalle {
        flex-direction: column;
        padding: 0rem;
    }
    .video-div {
        padding: 1rem;
    }
    .informacion-curso-detalle {
        flex-direction: column;
    }
    .profesores-div-detalle {
        flex-direction: column;
        flex-wrap: wrap;
    }
    .curso-disertantes .integrante-card {
        zoom: 0;
        margin: 0;
    }
    .info-inscribirse {
        flex-direction: column;
        width: 100%;
    }

    .curso-disertantes .integrante-card:last-of-type {
        margin-left: 0;
    }
    .acordion-info-div {
        width: 90%;
    }
    .btn-inscribirse-div {
        width: 100%;
    }
    .btn-inscribirse {
        margin-bottom: 2rem;
    }
    .certificaciones {
        margin-bottom: 1rem;
        margin-top: 1.5rem;
    }
    .certificaciones-icon-div {
        zoom: 0.7;
    }
}

@media (max-width: 1500px) {
    .cursodetalle-container h1 {
        font-size: 3.3rem;
    }
}