.statistics-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../assets/img/counter-bg.jpg');
    background-size: cover;
    flex-direction: column;
    overflow-y:hidden;
    overflow-x:hidden;
    padding-top: 3rem;
    z-index: 50;
    position: relative;
}

.statistics-flex-container {

    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
}
.counter-table {
    color: white;
    display: flex;
    flex-direction: row;
    font-family: 'Lexend', sans-serif;
    padding-top: 4rem;
    padding-bottom: 6rem;
}
.counter-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
}
.counter-card:last-child {
    margin-right: 0;
}
.counter {
    font-weight: bold;
    font-size: 60px;
}
.counter-sum {
    font-size: 40px;
    line-height: 10px;
}
@media (max-width: 720px) {
    .counter-table {
        flex-direction: column;
    }
    .counter-card {
        margin-bottom: 3.5rem;
    }
}
@media (max-width: 1500px) {


}