.testimonials-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #b175ac;
    flex-direction: column;
    overflow-y:hidden;
    overflow-x:hidden;
    padding-top: 3rem;
    z-index: 50;
    position: relative;
}

.carousel-div {
    width: 100%;
    display: flex;
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.testimonial-flex-container {

    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
}
.phone-title-carousel {
    display: none;
}

@media (max-width: 720px) {

    .carousel-div {
        max-width: 100%;
        margin: 0;
    }
    .testimonial-flex-container {
        width: 100%;
        padding-bottom: 3rem;
    }
    .phone-title-carousel {
        display: block;
        margin-top: 2rem;
        color: white;
        text-transform: uppercase;
        font-family: 'Lexend', sans-serif;
        font-weight: bold;
        background-color: rgba(143, 49, 119, 0.568);
        padding: 1rem;
    }

}
@media (max-width: 1500px) {
    .caller-container h1 {
      font-size: 3.3rem;
    }
    .info-pago-imagen h1 {
        font-size: 1.8rem;
    }

  }