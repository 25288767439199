.hook-container {
    width: 100%;
    height: auto;
    display: flex;
    min-height: auto;
    justify-content: center;
    align-items: center;
    background-image: url('../../assets/img/hook-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    flex-direction: column;
    overflow-y:hidden;
    overflow-x:hidden;
    z-index: 50;
    position: relative;
    padding-top: 4rem;
    padding-bottom: 3rem;
}

.hook-flex-container {

    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
}
.hook-title {
    font-size: 1.7rem;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Lexend', sans-serif;
    margin-bottom: 1rem;
    padding: 0.4rem 1rem 0.4rem 1rem;
    background-color: #6e3a7a;
}
.hook-effect {
    transition: all 0.3s;
    animation: zoominout 5s ease-in-out infinite;
}
.chevrones {
    margin-bottom: 6rem;
    zoom: 0.8;
}

@media (max-width: 720px) {
    .chevrones {

    }
}
@media (max-width: 1500px) {


}

@keyframes zoominout {
    25% {
     scale: 1.1;
       }
    33.3% {
     scale: 1;
    }
    66.6% {
     opacity: 1.14;
    }
    100% {
     scale: 1;
    }
   }