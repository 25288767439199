.footer-container {
    height: auto;
    display: flex;
    background-image: url('../../assets/img/hook-bg.jpg');
    overflow-x:hidden;
    background-size: cover;
    justify-content: space-evenly;
    padding-top: 3rem;
    padding-bottom: 3rem;
    align-items: center;
    margin: 0;
}
.logo-footer {
    width: 130px;
    filter: brightness(0) invert(1);
}
.footer-text {
    color: white;
    text-align: center;
    font-size: 1rem;
    font-family: 'Lexend', sans-serif;
}

.info-buy-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.info-footer-imagen {
    display: flex;
    justify-content: flex-end;
    width: 40%;
    height: auto;
    padding: 0;
    
}
.paypal {

}
.footer-title {
    font-size: 1.4rem;
    font-family: 'Lexend', sans-serif;
    
}
.info-footer-imagen h1 {
    margin: 0;
    font-size: 2rem;
    padding: 1rem 2rem 1rem 2rem;
    text-align: end;
    color: #ffffff;
    margin-right: 2rem;
    background-color: #ff7e6d;
}

oferta {
    background-color: #6e3a7a;
    padding: 0.3rem
}
oferta:hover {
    background-color: #8f559c;
    cursor: pointer;
}
.footer-title {
    width: 100%;
    font-size: 2rem;
    font-family: 'Tahu', sans-serif;
    color: #ffffff;
}
.footer-flex-container {

    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.margin {
    margin-top: 2rem;
}
.footer-button-class {
    padding-top: 2rem;
    text-align: center;
    justify-content: center;
    text-align: center;
}
.button-start {
    padding: 1.3rem 3rem 1.3rem 3rem;
    border-radius: 6px;
    border-color: transparent;
    background-color: rgb(250, 233, 135);
    font-family: 'Lexend', sans-serif;
    color: rgb(190, 4, 236);
    transition: all 0.3s;
    font-size: 1rem;
}

.button-start:hover {
    transform: translateY(-15%);
    background-color: rgb(255, 190, 116);
    cursor: pointer;

}
.info-footer-adquirir {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.info-footer-adquirir h1 {
    padding-top: 1rem;
}
.boton-red:hover {
    cursor: pointer;
}
@media (max-width: 720px) {
    .footer-container h1 {
        font-size: 3rem;
        font-family: 'Lexend', sans-serif;
    }
    .footer-container {
        flex-direction: column;
    }
    .info-buy-footer {
        margin-bottom: 2rem;
    }
    .info-buy {
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center !important;
        padding-bottom: 0.3rem;
    }
    .info-footer-imagen {
        width: 100%;
        padding-bottom: 1rem;
    }
    .info-footer-imagen h1 {
        font-size: 2rem !important;
        text-align: center !important;
    }
    .footer-title {
        width: 100%;
        text-align: center;
        
    }
    .footer-flex-container {

        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-self: center;
    }

    .footer-title {
        margin-top: 0.3rem;
 
    }

}
@media (max-width: 1500px) {
    .footer-container h1 {
      font-size: 3.3rem;
    }
    .info-footer-imagen h1 {
        font-size: 1.8rem;
    }
  }