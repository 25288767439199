.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.cdnfonts.com/css/oceanwide');
@import url('https://fonts.cdnfonts.com/css/nature-beauty-personal-use');
@import url('https://fonts.cdnfonts.com/css/garant-pro');
@import url('https://fonts.cdnfonts.com/css/zt-gatha-95306');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css');
@import url('https://fonts.cdnfonts.com/css/inclusive-sans-2');
@import url('https://fonts.cdnfonts.com/css/lexend');
@import url('https://fonts.cdnfonts.com/css/rokuna-alenthush');
@import url('https://dl.dafont.com/dl/?f=tahu');
@font-face {
  font-family: 'Tahu';
  src: url('./assets/fonts/tahu.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }
body {
  overflow-x: hidden;
}
.html {
  scroll-behavior: smooth;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
