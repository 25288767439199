.whatsapp-floating {
    position: fixed;
    width: 100px;
    bottom: 30px;
    right: 30px;
    transition: all 0.3s;
    z-index: 100;
}
.whatsapp-floating:hover {
    scale: 1.1;
    cursor: pointer;
}
@media (max-width: 720px) {
    .whatsapp-floating {
        right: 10px;
        bottom: 20px;
        width: 80px;
    }
}