.cursos-container {
    width: 100%;
    min-height: 100vh;
    max-width: 100%;
    overflow-x: hidden;
    height: auto;
    display: flex;
    flex-direction: row;
    z-index: 2;
    background-image: url('../../assets/img/cursos-bg.jpg');
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 4rem;
    padding-bottom: 4rem;
}
.section-title {
    color: white;
    font-size: 2.3rem;
    font-family: 'Lexend', sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: 0.1rem;
    margin-bottom: 2rem;
    position:relative;
    display:inline-block;
}

.section-title:after {
    content:'';
    position:absolute;
    left:0; right:0;
    top:100%;
    margin:3px auto;
    width:20%;
    height:6px;
    background:rgb(255, 255, 255);
}
.title-inverted {
    color: rgb(158, 48, 158);
}
.title-inverted:after {
    background: rgb(158, 48, 158);
}
.cursos-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70%;
    margin-top: 2rem;
}
.curso-card {
    display: flex;
    flex-direction: row;
    background-color: white;
    margin-bottom: 1.5rem;
    border-radius: 15px;
    font-family: 'Lexend', sans-serif;
    box-shadow: 2px 2px 14px -2px rgba(0,0,0,0.48);
    -webkit-box-shadow: 2px 2px 14px -2px rgba(0,0,0,0.48);
    -moz-box-shadow: 2px 2px 14px -2px rgba(0,0,0,0.48);
    transition: scale 0.6s;
}
.curso-card:hover {
    scale: 1.02
}
.curso-title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2rem;
    border-left: 6px solid rgb(228, 120, 255);
    padding-left: 1rem;
    margin-bottom: 1rem;
}
.curso-desc {
    font-size: 0.9rem;
    line-height: 1.2rem;
    text-align: justify;
}
.curso-profes {
    font-size: 0.8rem;
    line-height: 1rem;
    text-align: justify;
    border-left: 4px solid rgb(228, 120, 255);
    padding-left: 1rem;
    text-align: left;
}
.curso-profes-ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.4rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
}
.btn-mas {
    padding: 0.7rem 1.5rem 0.7rem 1.5rem;
    background-color: rgb(194, 87, 194);
    color: white;
    border-radius: 25px;
    transition: all 0.3s;
    
}
.btn-mas:hover {
    background-color: rgb(211, 113, 211);
}
.curso-info {
    padding: 2rem;
    width: 100%;
    max-width: 50%;
}
.curso-img {
    width: 50%;
    max-width: 50%;
    background-size: cover;
}
.img-curso {
    width: 100%; /* Ancho de la imagen al 100% del contenedor */
  height: 100%; /* Altura de la imagen al 100% del contenedor */
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

@media (max-width: 720px) {
    .curso-card {
        width: 93%;
        flex-direction: column-reverse;
       
    }
    .curso-profes-ul {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .btn-mas-div {
        margin-top: 1rem;
        width: 100%;
        align-self: center;
        justify-self: center;
        
    }
    .btn-mas {
        width: 100%;
        text-align: center;
    }
    .curso-img {
        width: 100%;
        max-width: 100%;
    }
    .img-curso {
        width: 100%;
    }
    .curso-info {
        width: 100%;
        max-width: 100%;
        padding: 2rem 2rem 1rem 2rem;
    }
    .cursos-list {
        width: 90%;
    }
    .img-curso {
        width: 100%;
        border-radius: 15px;

    }
    .btn-mas-div {
        display: flex;
        justify-content: center;

    }
}
@media (max-width: 1366px) {
    .curso-card {
        width: 100%;
    }
}
@media (min-width: 1367px) and (max-width: 1921px)  {
    
    .curso-card {
        width: 90%;
        
    }
}
@media (min-width: 1920px) {
    .curso-card {
        width: 75%;
    }
}