.nav-container {
    width: 100%;
    height: 5.5rem;
    background-color: transparent;
    z-index: 999;
    font-family: 'Garant Pro', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-family: 'ZT Gatha', sans-serif;
    position: fixed;
    top: 0;
    transition: all 0.5s;
}
.nav-container-white {
    width: 100%;
    height: 5rem;
    background-color: rgba(150, 61, 145, 0.884);
    z-index: 999;
    font-family: 'Garant Pro', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-family: 'ZT Gatha', sans-serif;
    position: fixed;
    top: 0;
    transition: all 0.5s;
}
.menu-phone {
    display: none;
}
.get-started {
    border: 2px solid rgb(255, 255, 255);
    padding: 1rem;
    transition: all 0.3s;
    border-radius: 6px;
}
.get-started:hover {
    border: 2px solid #ef7dda;
}
.get-started-white:hover {
    border: 2px solid #ef7dda;
}
.nav-logo {
    width: 90px;
    color: rgb(255, 255, 255);
    font-family: 'Nature Beauty Personal Use', sans-serif;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.8rem;
    filter: brightness(1000%);
}
.logo-phone {
    display: none;
}
.nav-logo-white {
    width: 90px;
    font-family: 'Nature Beauty Personal Use', sans-serif;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.8rem;
    filter: brightness(1000%);
}
.nav-logo:hover {
    cursor: pointer;
}

.nav-logoside {
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
}

.nav-button {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    color: white;
    transition: all 0.3s;
}
.nav-button:hover {
    transform: translateY(-20%);
    color:#6e3a7a;
}
.nav-button-white {
    margin-right: 1rem;
    margin-left: 1rem;
    color: white;
    transition: all 0.2s;

}
.icon-menu {
    filter: brightness(0) invert(1);
}
.nav-button-white:hover {
    transform: translateY(-20%);
    color:#fc93e8;
    border-bottom: 4px solid #fc93e8;
}
.nav-buttonsside {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    color: white;
}
@media (max-width: 720px) {
    .menu-phone {
        display: block;
        margin-right: 2rem;
    }
    .nav-container {
        justify-content: space-between;
    }
    .nav-container-white {
        justify-content: space-between;
    }
    .logo-phone {
        display: block;
        
        position: absolute;
        top: 20px;
        left: 40px;
    }
    .logo-phone-img {
        width: 90px;
        filter: brightness(0) invert(1);
    }
    .nav-logo-white {
        display: none;
    }
    .nav-pc {
        display: none;
    }
    .nav-container-white {
        top: -5.8rem;
    }
    .nav-logo {
        display: none;
    }
}
@media (max-width: 1500px) and (min-width: 720px) {
    .nav-container {
        zoom: 85%;
    }
    .nav-container-white {
        zoom: 85%;
    }
  }