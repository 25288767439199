icono {
    margin-bottom: 0.1rem;
    margin-right: 0.2rem;
}
.cover-container {
    width: 100%;
    min-height: 100vh;
    max-width: 100%;
    overflow-x: hidden;
    height: auto;
    display: flex;
    flex-direction: row;
    background: url("../../assets/img/bg-main.jpg");
    background-size: cover;
    z-index: 2;
}
.boton-wpp-cover {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.4rem;
    font-size: 1.2rem;
    color: white;
    line-height: 0;
    padding: 0.7rem 1.5rem 0.7rem 1.5rem;
    font-family: 'Lexend', sans-serif;
    background-color: transparent;
    transition: all 0.3s;
    background-color: #a249a5;
    border-radius: 50px;
    display: none;
}
.boton-wpp-cover:hover {
    scale: 1.03;
    background-color: #c875cb;
}
.person-cover-img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 42%;
    opacity: 0.75;
    -webkit-filter: drop-shadow(35px 35px 20px rgba(0, 0, 0, 0.5));
    filter: drop-shadow(35px 35px 20px rgba(20, 20, 20, 0.5));
}


.cover-title-h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6rem;
    width: 50%;
    flex-direction: column;
}
.title-cover {
    color: white;
    margin-bottom: 1rem;
    font-size: 2rem;
    padding: 0.7rem 1rem 0.7rem 1rem;
    border-top-left-radius: 20px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 20px;
    background-color: #a249a5;
    font-family: 'Rokuna Alenthush', sans-serif;
    letter-spacing: 0.05rem;
}
.subtitle-h1 {

    font-family: 'Rokuna Alenthush', sans-serif;
    color: #ffffff;
    text-align: center;
    z-index: 2;
    font-size: 2.1rem !important;
}

.cover-flex-container {
    max-width: 100%;
    margin-top: 3.5rem;
    display: flex;
    padding: 5rem;
}

.cover-title {
    display: flex;
    margin-top: 3rem;
}

.cover-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.button-cover-nav {
    color: white;
    padding: 0.8rem;
    border-radius: 50%;
    background-color: #a950ac;
    margin: 0.5rem 1rem 1rem 1rem;
    transition: all 0.4s;
}
.button-cover-nav:hover {
    cursor: pointer;
    background-color: #c875cb;
}

@media (max-width: 720px) {
    .cover-nav {
        display: none;
    }
    .cover-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
        overflow: hidden;
    }
    .cover-flex-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 !important;
        
    }
    .cover-title-h1 {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 140%;
        margin-top: 3rem; 
        margin-left: 0;
    }
    .title-cover {
        font-size: 1.7rem;
        text-align: center;
        border-top-left-radius: 40px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 40px;
        background-color: #a249a5c0;
    }
    .person-cover-img {
        width: 120%;
        margin-top: 50px;
        display: block;
        margin-bottom: -110px;
        position: static;
        opacity: 0.8;
    }
    .subtitle-h1 {
        font-size: 1.4rem !important;
        margin: 0;
    }
    .mandala {
        display: none;
    }
    .boton-wpp-cover {
        display: block;
    }
    .about-me {
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .about-text {
        align-self: center;
        text-align: center;
        padding: 1rem 1rem 3rem 1rem;
    }
    .linear-spir {
        display: none;
    }
    .cover-video {
        width: 100%;
    }
    .boton-wpp-cover {
        zoom: 0.9
    }
}
@media (max-width: 1900px) and (min-width: 1501px) {

    .cover-title-h1 {
      align-items: center;
      justify-content: center;
      align-self: center;
    }
    .subtitle-h1 {
        font-size: 1.9rem !important;
    }
    .title-cover {
        font-size: 1.4rem !important;
    }
    .boton-wpp-cover {
        font-size: 1.2rem;
    }
  }
@media (max-width: 1500px) and (min-width: 720px) {
    .about-photo-img{
        width: 140px;
    }
    .about-description {
        font-size: 1rem;
    }
    
    .cover-title-h1 {
      align-items: center;
      justify-content: center;
      align-self: center;
    }
    .subtitle-h1 {
        font-size: 1.5rem !important;
    }
    .title-cover {
        font-size: 1.2rem !important;
    }
    .boton-wpp-cover {
        font-size: 1rem;
    }
    .cover-video {
        width: 500px;

    }
    .mandala {
        left: -300px;
    }
  }

